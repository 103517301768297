<div class="summary">
    <div class="item">
        <div class="title">Total calls</div>
        <div class="value">{{ summaryObj.totalCalls | number }}</div>
    </div>
    <div class="item">
        <div class="title">Minutes</div>
        <div class="value">
            {{ summaryObj.totalMinutes | number : "1.0-0" }}
        </div>
    </div>
    <!-- <div class="item">
        <div class="title">Cost total</div>
        <div class="value">{{ summaryObj.totalCost | currency }}</div>
    </div>
    <div class="item">
        <div class="title">Average cost per call</div>
        <div class="value">{{ summaryObj.averageCost | currency }}</div>
    </div> -->
</div>
