import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    imageUrl: string = '';
    backgroundColor = environment.backgroundColor;

    constructor() {
        this.imageUrl = environment.logoUrl;
    }
}
