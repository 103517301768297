import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from '../../../../services/analytics.service';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-call-history',
    standalone: true,
    imports: [CommonModule, FormsModule, MatTooltipModule],
    providers: [DatePipe],
    templateUrl: './call-history.component.html',
    styleUrl: './call-history.component.scss',
})
export class CallHistoryComponent {
    isPlaying: boolean = false;
    isInputFocused: boolean = false;
    callHistory: any = [];
    expandedRowIndex: number | null = null;
    totalPages: number = 1;
    pageIndex: number = 0;
    pageNumber: number = 1;
    pageSize: number = 12;
    recordingUrl: string = '';
    selectedRecordingIndex: number = -1;
    recordingPercentage: number = 0;
    searchKey: string = '';
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        this.getCallHistory();
    }

    toggleRow(index: number): void {
        this.expandedRowIndex = this.expandedRowIndex === index ? null : index;
    }

    onFocus() {
        this.isInputFocused = true;
    }

    onBlur() {
        this.isInputFocused = false;
    }

    getCallHistory() {
        this.pageIndex = this.pageNumber - 1;
        this._analyticsService
            .getCallHistory(
                this.pageIndex,
                this.pageSize,
                this.searchKey,
                this.timeZoneOffset
            )
            .subscribe((response) => {
                this.callHistory = response.list;
                this.totalPages = response.details.totalPages;
            });
    }

    getFormattedTime(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedTime = `${this.padZero(hours)}:${this.padZero(
            minutes
        )}:${this.padZero(remainingSeconds)}`;
        return formattedTime;
    }

    padZero(value: number): string {
        return value < 10 ? `0${value}` : `${value}`;
    }

    nextPage(): void {
        this.pageNumber++;
        this.getCallHistory();
    }

    previousPage(): void {
        this.pageNumber--;
        if (this.pageNumber < 1) {
            this.pageNumber = 1;
            return;
        }
        this.getCallHistory();
    }

    toggleAudio(url: string, index: number, event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        this.selectedRecordingIndex = index;
        let audioPlayer = <HTMLAudioElement>(
            document.getElementById('audioPlayer')
        );

        if (url !== this.recordingUrl) {
            audioPlayer.pause();
            this.recordingUrl = url;
            audioPlayer.load();
        }
        this.recordingUrl = url;

        if (!audioPlayer.buffered.length) {
            audioPlayer.load();
        }
        if (audioPlayer.paused) {
            audioPlayer.play();
            this.isPlaying = true;
            this.callHistory[index].isPlaying = true;
        } else {
            audioPlayer.pause();
            this.isPlaying = false;
            this.callHistory[index].isPlaying = false;
        }

        // stop all other calls
        for (let i = 0; i < this.callHistory.length; i++) {
            if (i !== index) {
                this.callHistory[i].isPlaying = false;
            }
        }
        this.recordingPercentage = 0;
    }

    audioEnded() {
        this.isPlaying = false;
    }

    downloadAudio(href: string, date: string, event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        fetch(href)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${date}.mp3`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(link.href);
                    link.remove();
                }, 100);
            });
    }

    gradientStyle() {
        return `radial-gradient(closest-side, white 70%, transparent 71% 100%), conic-gradient(#16aeae ${this.recordingPercentage}%, #5ae3e1 0)`;
    }

    updatePlaybackPercentage() {
        let audioPlayer = <HTMLAudioElement>(
            document.getElementById('audioPlayer')
        );

        this.recordingPercentage =
            (audioPlayer.currentTime / audioPlayer.duration) * 100;
    }

    onPlaybackEnded() {
        this.isPlaying = false;
        this.callHistory[this.selectedRecordingIndex].isPlaying = false;
    }
}
