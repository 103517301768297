<app-header></app-header>

<div class="page">
    <div class="content-wrapper">
        <div class="top-row">
            <div class="title">Analytics</div>
            <div class="date-wrapper">
                <mat-form-field appearance="fill">
                    <mat-datepicker-toggle
                        matPrefix
                        [for]="picker"
                    ></mat-datepicker-toggle>

                    <mat-date-range-input [rangePicker]="picker">
                        <input
                            matStartDate
                            placeholder="Start date"
                            matInput
                            [(ngModel)]="startDate"
                        />
                        <input
                            matEndDate
                            placeholder="End date"
                            matInput
                            [(ngModel)]="endDate"
                        />
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <!-- <div class="date-range">Jan 11, 2023 - Jan 17, 2023</div> -->
        </div>
        <div class="row">
            <app-summary
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-summary>
        </div>
        <!-- <div class="row">
            <app-tickets></app-tickets>
            <app-contacts></app-contacts>
        </div> -->
        <div class="row">
            <app-calls-made
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-calls-made>
            <app-call-vs-time
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-call-vs-time>
        </div>
        <div class="row">
            <app-call-history></app-call-history>
        </div>
        <!-- <div class="row">
            <app-call-result
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-call-result>
            <app-cost-analysis
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-cost-analysis>
            </div>
        <div class="row">
            <app-cost-breakdown
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-cost-breakdown>
            <app-call-overflow
                [startDate]="startDate"
                [endDate]="endDate"
            ></app-call-overflow>
        </div> -->
    </div>
</div>
